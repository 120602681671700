h1,
.h1 {
  font-size: 1.5rem;
  font-weight: 100;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  color: $light-text;
  text-transform: uppercase;
  font-size: 0.875rem;
}

h5,
.h5 {
  font-size: 0.875rem;
  font-weight: 600;
}

p {
  margin-bottom: 0.635rem;
}

a {
  text-decoration: none;
  color: $light-blue;

  &:hover {
    color: $dark-blue;
  }
}

.small {
  font-size: 85%;
  line-height: 1.3rem;
}

.text-muted {
  color: $gray5 !important;
}

.text-success {
  color: $blue !important;
}

.text-green {
  color: $green !important;
}

.text-red {
  color: $red !important;
}
