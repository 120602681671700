.alert {
  &-success {
    color: $alert-green1;
    background-color: $alert-green2;
    border-color: $alert-green3;
  }

  &-secondary {
    color: $alert-secondary1;
    background-color: $alert-secondary2;
    border-color: $alert-secondary3;
  }
}
