.e-schedule {
  overflow: visible;
  min-height: 600px;
  height: calc(100vh - 300px) !important;

  .e-toolbar {
    .e-toolbar-items .e-add {
      pointer-events: none;
      display: none !important;
    }

    .e-tbar-btn:focus {
      background: $dark-blue;

      .e-tbar-btn-text {
        color: white;
      }
    }
  }

  .e-month-view .e-current-date .e-date-header {
    background-color: $red;
  }

  .e-vertical-view .e-time-cells-wrap table td,
  .e-month-view .e-work-cells,
  .e-month-agenda-view .e-work-cells,
  .e-vertical-view .e-work-hours,
  .e-vertical-view .e-work-cells {
    background: white;
  }

  .e-vertical-view .e-header-cells.e-current-day,
  .e-more-popup-wrapper .e-more-event-date-header .e-current-date,
  .e-month-view .e-date-header-wrap table td.e-current-day,
  .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
  .e-vertical-view .e-clone-time-indicator,
  .e-vertical-view .e-current-time,
  .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
  .e-schedule-toolbar .e-active-view .e-icons {
    color: $red;
  }

  .e-vertical-view .e-time-cells-wrap .e-time-cells {
    // border-bottom-color: rgba(0, 0, 0, 0.12);
  }

  .e-event-resize.e-top-handler,
  .e-event-resize.e-bottom-handler {
    max-height: 10%;
  }

  .current-date {
    background: $gray11 !important;

    &.e-work-cells:hover {
      background: #f2f4f6 !important;
    }
  }

  .e-vertical-view .e-current-timeline,
  .e-vertical-view .e-previous-timeline {
    border-color: $red;
  }

  .e-month-view .e-date-header,
  .e-month-view .e-date-header-wrap table td,
  .e-month-agenda-view .e-date-header-wrap table td {
    font-weight: 400;
    font-size: 0.8rem;
  }

  .is-done {
    text-decoration: line-through;
    opacity: 0.75;
  }

  .e-table-wrap:not(.e-agenda-view) .e-content-wrap,
  .e-vertical-view .e-content-wrap,
  .e-more-popup-wrapper {
    .e-appointment {
      border-radius: 4px;
      padding: 0;

      .e-appointment-details {
        padding: 0 !important;
        height: 100%;
      }

      .event-template-wrapper {
        height: 100%;
        width: 100%;
        padding: 2px 2px 2px 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.7rem;

        &.with-border {
          border-left: 5px solid;
          padding-left: 2px;
        }
      }
    }
  }

  .e-agenda-view {
    white-space: normal;

    .e-agenda-cells:first-child {
      display: none;
    }

    .e-day-border {
      border: none;
    }

    .e-level-1 {
      padding: 0;

      .e-appointment.e-template {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0;
        border-left: 0;

        &:hover {
          background: $gray3;
        }
      }
      &:not(:last-child) .e-appointment.e-template {
        border-bottom: 1px solid $gray7;
      }

      .agenda-content {
        width: 100%;
        padding: 0.5rem 0;
        font-size: 0.9rem;
        border-left: 0;

        &.with-border {
          border-left: 3px solid transparent;
        }

        .time-column {
          @media (max-width: 767px) {
            margin-left: 2.8rem;
            font-style: italic;
          }

          @media (min-width: 768px) {
            min-width: 110px;
          }
        }

        .color-circle {
          position: relative;
          margin-top: 0.2rem;
          width: 20px;
          height: 20px;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            font-size: 0.8rem;
            line-height: 0.8rem;
          }
        }
      }
    }
  }
}

.schedule-context-menu {
  ul .e-menu-item .e-menu-icon {
    font-size: 12px;
  }

  .e-menu-item {
    .new::before {
      content: '\e805';
    }

    .add-announcement::before {
      content: '\e82c';
    }

    .edit::before {
      content: '\e730';
    }

    .delete::before {
      content: '\e820';
    }

    .show::before {
      content: '\e77a';
    }
  }
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  color: $red;
}

.e-spinner-pane .e-spinner-inner .e-spin-material,
.e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 {
  stroke: $red;
}

.e-calendar .e-btn.e-today.e-flat.e-primary,
.e-calendar .e-css.e-btn.e-today.e-flat.e-primary {
  background-color: $primary-green;
  border-color: $primary-green;

  &:hover,
  &:active {
    background-color: $primary-green;
    border-color: $primary-green;
  }
}

.e-calendar .e-content.e-month td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-month td.e-today span.e-day {
  line-height: 32px;
}

.e-calendar .e-content-table .e-cell.e-focused-cell span.e-day,
.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day,
.e-calendar .e-content td.e-focused-date span.e-day,
.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-calendar .e-content td.e-focused-date:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:focus span.e-day,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:focus,
.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:hover {
  border-radius: 50%;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  box-shadow: inset 1px 0 $light-blue, inset 0 1px $light-blue, inset -1px 0 $light-blue, inset 0 -1px $light-blue;
  color: $light-blue;
  border-radius: 50%;
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: $red;
  border-color: $red;
  border-radius: 50%;
  color: white;
}

.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
  font-size: 0.8rem;
  border-width: 1px !important;
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.4);
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover,
.e-bigger.e-small .e-calendar .e-header .e-next:hover {
  background: transparent;

  span {
    color: #000;
  }
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  color: #6c757d;
  margin: 2px 0 0;
  font-weight: 400;

  &:hover {
    color: #000;
  }
}

.e-toolbar .e-tbar-btn:hover {
  background: $dark-blue;
  border-color: $dark-blue;
}

.e-bigger .e-more-popup-wrapper.e-device,
.e-more-popup-wrapper.e-device {
  position: fixed;

  .e-more-event-date-header .e-current-date {
    color: $red;
  }

  .e-appointment {
    padding: 3px 5px;
    height: auto;
    min-height: 26px;

    .is-done {
      text-decoration: line-through;
      opacity: 0.75;
    }
  }
}
