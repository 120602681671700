$light-blue: #557590;
$dark-blue: #2b3b4a;

$orange: #f8ac59;
$green: #19aa8d;
$red: #ed5565;
$lazur: #23c6c8;
$blue: #1c84c6;
$blue2: #1a7bb9;

$primary-green: #1ab394;

$text: #595b5c;
$light-text: #9ea6b9;
$light-text-hover: #425b70;

$body-background: #f4f6fa;
$nav-background: #2f4050;
$light-blue-background: #f8fafb;

$gray1: #e7eaec;
$gray2: #86898e;
$gray3: #e5e5e5;
$gray4: #676a6c;
$gray5: #888888;
$gray6: #cccccc;
$gray7: #eee;
$gray8: #e6e6e6;
$gray9: #e9ecef;
$gray10: #262626;
$gray11: #f9f9f9;
$gray12: #f5f5f5;
$gray13: #dddddd;
$gray14: #555;
$gray15: #d1dade;
$gray16: #5e5e5e;
$gray17: #ced4da;

$black1: #000;
$black2: #333;

$green2: #607556;
$green3: #18a689;

$alert-green1: #3c763d;
$alert-green2: #dff0d8;
$alert-green3: #d6e9c6;

$alert-secondary1: $text;
$alert-secondary2: $gray11;
$alert-secondary3: $gray17;

$error: #a94442;

$male: #99cdff;
$female: #ff97cd;
