.dropdown-item {
  color: $light-blue;
  font-size: 0.8rem;
  padding: 0.4rem 1rem;
  white-space: normal;

  &:active {
    background-color: $gray9;
    color: $gray10;
  }
}
