.navigation {
  position: relative;
  width: 220px;
  background: $nav-background;
  min-height: 100vh;
  margin-left: 0;
  transition: width 0.4s;

  &.narrow-menu,
  &.mobile {
    transition: all 0.4s;
    width: 70px;

    app-navigation {
      .navigation-inner {
        width: 70px;

        .header {
          display: none;
        }

        .header-narrow {
          display: block !important;
        }

        .menu {
          li a {
            .icon {
              font-size: 16px;
            }

            span {
              display: none !important;
            }
          }
        }
      }
    }
  }

  &.mobile {
    &.narrow-menu {
      transition: all 0.4s;
      visibility: hidden;
      margin-left: -70px;
    }
  }
}

.tab-container {
  .nav-tabs {
    .nav-item {
      &.active {
        .nav-link {
          color: $gray14;
          border-color: $gray13;
          border-bottom-color: transparent;

          &:hover,
          &:focus {
            background: white;
            color: $gray14;
          }
        }
      }

      .nav-link {
        border-radius: 0;
        color: $light-text;
        font-weight: 600;

        &:hover,
        &:focus {
          background: $gray8;
          color: $light-text-hover;
        }
      }
    }
  }

  .tab-pane {
    > .card {
      border-top: 0;
    }
  }
}
