.card {
  border-radius: 0;
  border-color: $gray1;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-bottom-color: $gray1;
    padding: 15px;

    &.collapsable {
      cursor: pointer;

      .icon-arrow {
        cursor: pointer;
        padding: 0 10px;
        transition: all 0.5s;
        transform: translateY(-2px);
      }

      &.collapsed .icon-arrow {
        transform: rotate(180deg) translateY(2px);
      }
    }

    .header {
      display: inline-block;
      margin: 0;
      text-overflow: ellipsis;
    }

    a {
      @extend .small;
      line-height: 1;
    }
  }
}
