label:not(.form-check-label) {
  font-weight: 700;
}

.row-inline {
  label:not(.form-check-label) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 10px;
    position: relative;
    margin-right: -10px;

    > span.asterisk {
      position: absolute;
      right: 0;
    }
  }
}

.form-control,
.form-select,
.ng-select .ng-select-container {
  border-radius: 1px;
  font-size: 0.875rem;
  color: inherit;
  border-color: $gray17;

  &:focus {
    border: 1px solid $primary-green;
    box-shadow: none;
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened) {
    > .ng-select-container {
      border-color: $primary-green;
      box-shadow: none;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: $gray9;
    white-space: normal !important;

    .ng-value-icon.right {
      border-color: $gray6;
      margin-left: 5px;
    }
  }

  .ng-option,
  .ng-value {
    display: flex;
    align-items: center;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal;
  }

  .select-shape {
    display: inline-block;
    padding: 7px;
    margin-right: 7px;
    margin-left: 5px;

    &.circle {
      border-radius: 50%;
    }
  }

  .ng-clear-wrapper {
    margin-top: 3px;
  }
}

.input-group-text {
  background: white;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 0.875rem;
}

input,
textarea,
select {
  &.ng-dirty.ng-touched.ng-invalid {
    border-color: $error;
  }
}

app-datepicker.ng-dirty.ng-touched.ng-invalid,
app-timepicker.ng-dirty.ng-touched.ng-invalid {
  input,
  .e-input-group {
    border-color: $error !important;
  }

  .icon,
  .e-input-group-icon:before {
    color: $error !important;
  }
}

.ng-select {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    .ng-optgroup {
      font-size: 0.8rem;
      background: $gray7;
      color: $black2;
    }

    .ng-option-child {
      position: relative;
      font-size: 0.75rem;

      &:before {
        content: '';
        position: absolute;
        left: 8px;
        top: 14px;
        height: 5px;
        width: 5px;
        background: $gray6;
        border-radius: 50%;
      }
    }

    .ng-option.ng-option-marked {
      background: $light-blue;
      color: white;
    }
  }

  .ng-select-container .ng-value-container .ng-placeholder {
    color: $gray4;
  }

  &.auto-grow {
    @media (min-width: 576px) and (max-width: 649px) {
      width: 155px;
    }

    @media (min-width: 650px) and (max-width: 1199px) {
      width: 180px;
    }

    .ng-dropdown-panel {
      width: 100%;
    }
  }

  &.ng-dirty.ng-touched.ng-invalid {
    .ng-select-container {
      border-color: $error;
    }
  }
}

.asterisk {
  &.required {
    color: $error;
    font-weight: 300;
  }
}

.btn-check {
  &:focus {
    + .btn-outline-secondary {
      box-shadow: none;
    }
  }

  &:active,
  &:checked {
    + .btn-outline-secondary {
      background: lighten($light-blue, 5%);
      border-color: lighten($light-blue, 5%);
    }
  }
}

.form-check-input {
  &:checked {
    background-color: lighten($light-blue, 5%);
    border-color: lighten($light-blue, 5%);
  }
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: $light-blue;
}
