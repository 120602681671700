.bs-datepicker-container {
  padding: 0;

  .bs-datepicker-head {
    background-color: $dark-blue;
  }
}

// timepicker
// hide visual timepicker, but keep handling time in datepicker
.bs-timepicker-in-datepicker-container {
  display: none !important;
}

// daterangepicker
.md-drppicker .ranges ul li button.active,
.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: $light-blue !important;
}

.md-drppicker td {
  opacity: 1 !important;
}

.md-drppicker.double {
  font-family: 'Open Sans';
  top: 45px !important;
  padding: 0;

  .ranges ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  .calendar {
    &.left,
    &.right {
      margin: 0 auto !important;
    }
  }

  .buttons_input {
    .btn {
      box-shadow: none;
      text-transform: capitalize;
      font-family: 'Open Sans';
      padding: 1px 14px;
      border-radius: 3px;
      margin-bottom: 5px;

      + .btn {
        margin-left: 5px;

        &:last-child {
          color: white;
        }
      }
    }

    .btn-default {
      @extend .btn-outline-secondary;
      border: 1px solid;
    }

    .clear {
      border: none;

      svg {
        display: none;
      }
    }

    .btn:not(.clear):not(.btn-default) {
      @extend .btn-primary;
    }
  }
}

@media (max-width: 640px) {
  .md-drppicker.ltr .calendar,
  .md-drppicker.ltr .ranges {
    float: none !important;
  }

  .md-drppicker .buttons {
    border-top: 1px solid $gray6;
    text-align: center !important;
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .md-drppicker.ltr .ranges {
    float: none !important;
  }

  .md-drppicker.double {
    max-width: 100%;
  }

  .ranges {
    border-bottom: 1px solid $gray6;
  }
}

@media (min-width: 768px) {
  .md-drppicker.double {
    width: 605px !important;

    .ranges ul {
      flex-direction: column;
    }
  }
}
