.dashboard {
  .icon {
    transition: all 0.4s;

    &-main {
      opacity: 0.65;
      color: white;
    }

    &-arrow {
      opacity: 0;
      color: $gray6;
      transform: translateX(-10px);
    }
  }

  a {
    &:hover {
      .icon {
        &-main {
          opacity: 1;
        }

        &-arrow {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
}
