.badge {
  font-weight: normal;
  font-size: 0.9em;
  padding: 5px 10px;

  &.bg-blue {
    background-color: $blue !important;
  }

  &.bg-green {
    background-color: $green !important;
  }

  &.bg-red {
    background-color: $red !important;
  }

  &.bg-secondary {
    color: $gray16;
    background-color: $gray15 !important;
  }

  &-outline {
    background-color: rgba(white, 0.5) !important;

    &-success {
      border: 1px solid $green;
      color: $green;
    }

    &-danger {
      border: 1px solid $red;
      color: $red;
    }
  }

  &.small {
    font-size: 0.7rem;
    line-height: 0.8;
    padding: 5px 10px;
  }
}
