body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  background: $body-background;
  color: $text;
  font-size: 14px;
  overflow-x: hidden;

  &.modal-open {
    overflow: hidden;
  }
}

hr {
  margin: 1.25rem 0;

  &.dashed {
    border-top: 1px dashed $gray1;
    opacity: 1;
  }

  &.light {
    color: $gray1;
    opacity: 1;
  }
}

ul.unordered {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0.635rem;
}

footer {
  background: white;
  border-top: 1px solid $gray1;
}

.bg-primary {
  background: $blue !important;
}

.bg-lazur {
  background: $lazur !important;
}

.bg-orange {
  background: $orange !important;
}

.bg-red {
  background: $red !important;
}

.bg-green {
  background: $green !important;
}
