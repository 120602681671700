.widget {
  position: relative;
  display: block;
  line-height: 1;
  height: 100%;

  &:after {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    content: '\f105';
    font-family: FontAwesome;
    position: absolute;
    bottom: 5px;
    right: 35px;
    font-size: 40px;
  }

  &:hover,
  &:focus {
    &:after {
      visibility: visible;
      opacity: 1;
      transition: all 0.3s ease-in-out;
      right: 15px;
      color: #fff;
    }
  }

  &.fadeInRight {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
    opacity: 0;
  }

  .card {
    height: 100%;
  }

  &-header {
    font-size: 1.05rem;
    font-weight: 600;
    margin: 7px 0;
  }

  &-text {
    font-size: 0.8rem;
    line-height: 1.25;
    margin: 7px 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
