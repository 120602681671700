.modal-header {
  padding: 30px 15px;
  justify-content: center;

  &.with-icon {
    flex-direction: column;
    text-align: center;
  }

  &.dialog {
    border-bottom: 0;
  }

  h5 {
    font-size: 1.65rem;
  }

  h6 {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .btn-close:not(.btn-action) {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .btn-actions {
    position: absolute;
    right: 1rem;
    top: 1rem;

    svg {
      color: red;
    }

    .btn-icon.btn-action {
      position: relative;
      right: 0;
      top: 0;
      box-sizing: content-box;
      width: 1em;
      height: 1em;
      padding: 0.25em 0.25em;
      border: 0;
      border-radius: 0.25rem;
      margin: 0.25rem 0.75rem 0 0;
      color: $black1;
      opacity: 0.5;

      &:last-child {
        margin: 0.25rem 0 0 0;
      }

      &:hover {
        opacity: 1;
      }

      &.btn-delete {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' stroke-width='2'%3E%3Cg stroke-width='2' transform='translate(0, 0)'%3E%3Cpath d='M20,9V21a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V9' fill='none' stroke='%23000000' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='miter'%3E%3C/path%3E%3Cline data-color='color-2' x1='1' y1='5' x2='23' y2='5' fill='none' stroke='%23000000' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='miter'%3E%3C/line%3E%3Cline x1='12' y1='12' x2='12' y2='18' fill='none' stroke='%23000000' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='miter'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='8' y2='18' fill='none' stroke='%23000000' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='miter'%3E%3C/line%3E%3Cline x1='16' y1='12' x2='16' y2='18' fill='none' stroke='%23000000' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='miter'%3E%3C/line%3E%3Cpolyline data-cap='butt' data-color='color-2' points='8 5 8 1 16 1 16 5' fill='none' stroke='%23000000' stroke-miterlimit='10' stroke-width='2' stroke-linecap='butt' stroke-linejoin='miter'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E")
          center/1em auto no-repeat;
      }

      &.btn-edit {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' stroke-width='2'%3E%3Cg stroke-width='2' transform='translate(0, 0)'%3E%3Cline data-cap='butt' data-color='color-2' fill='none' stroke='%23000000' stroke-width='2' stroke-miterlimit='10' x1='19' y1='9' x2='15' y2='5' stroke-linejoin='miter' stroke-linecap='butt'%3E%3C/line%3E%3Cpolygon fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' points='7,21 2,22 3,17 18,2 22,6 ' stroke-linejoin='miter'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E")
          center/1em auto no-repeat;
      }
    }
  }

  .appointment-type {
    position: absolute;
    left: 1rem;
    top: 1rem;

    .color-circle {
      position: relative;
      margin-top: 0.2rem;
      width: 2rem;
      height: 2rem;
      background-color: $light-blue;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
}

.modal-body {
  background: $light-blue-background;

  .doctor-name,
  .clinic-name {
    display: flex;
    align-items: center;

    .color-circle,
    .color-square {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 5px;
    }
  }
}

@media (min-width: 992px) {
  .modal-md {
    max-width: 600px;
  }
}
