.table {
  --bs-table-striped-bg: rgba(0, 0, 0, 0.03);
  --bs-border-color: rgb(231, 234, 236);

  @media (max-width: 767px) {
    thead {
      display: none;
    }

    tr {
      border: 1px solid $gray6;

      td {
        display: block;
        border: 0;
        padding: 3px 15px;

        &:before {
          content: attr(data-title);
          display: block;
          font-weight: 600;
          font-style: normal;
        }

        &:first-child {
          padding-top: 15px;
        }

        &:last-child {
          padding-bottom: 15px;
        }
      }
    }
  }
}

.table > :not(caption) > * > * {
  &.alert-danger {
    background-color: #f8d7da;
  }
}
