.btn {
  border-radius: 3px;
  font-size: 0.9rem;

  &.btn-xs {
    font-size: 0.7rem;
    padding: 0.25rem 0.5rem;
  }

  &.btn-sm {
    font-size: 0.8rem;
  }

  &.btn-lg {
    font-size: 1.15rem;
  }

  &.btn-primary {
    background: $primary-green;
    border-color: $primary-green;

    &:hover {
      background: $green3;
      border-color: $green3;
    }
  }

  &.btn-secondary {
    background: $blue;
    border-color: $blue;

    &:hover {
      background: $blue2;
      border-color: $blue2;
    }
  }

  &.btn-outline-secondary {
    border-color: $gray1;
    color: $text;
    background: white;

    &:hover {
      background: $gray8;
    }

    &:focus {
      box-shadow: 0 0 0 0.05rem rgb(108 117 125 / 50%);
    }
  }

  &.btn-circle {
    display: block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 1;
    font-size: 0.7rem;

    > * {
      transform: translateX(-50%) translateY(-50%);
      display: inline-block;
      position: relative;
      top: 50%;
      left: 50%;
    }
  }
}
